import noImage from "../../../assets/img/noImage.png";
import noImageHome from "../../../assets/img/home.png";
import {Delete, ImageAdd} from "../../Icons";
import {ChangeEvent, useEffect, useState} from "react";
import { v4 as uuid } from 'uuid';
import imgLogin from "../../../assets/img/bg-login-2x.png";
interface IImageInputProps {
    multiple?: boolean;
    addMoreType?: boolean;
    onAddImage?: (file: File) => void;
    onAddMultiple?: (file: File[]) => void;
    onRemoveImage?: () => void;
    profilePage?: boolean;
    previewFile?: File | '-1';
    disableAutoPreview?: boolean;
    bgImage?: string;
    urlPrevew?: string;
    imgSquare?: boolean;
    homeIcon?: boolean;
    width?: string;
    height?: string;
    id?: string;
    imgDefalt?: any;
}
export default function InputImage({
   addMoreType,
   onAddImage,
   multiple,
   onAddMultiple,
   previewFile,
   disableAutoPreview,
   onRemoveImage,
   bgImage,
   urlPrevew,
   imgSquare,
   homeIcon,
   width,
   height,
   id,
   imgDefalt
}: IImageInputProps){

    //const id = uuid();
    //const [preview, setPreview] = useState<string | ArrayBuffer | null>('');
    const [preview, setPreview] = useState<any>(urlPrevew);
    function convertFileToBase64(file: any, callback: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => callback(reader.result);
    }
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const reader = new FileReader();
        if (event.target.files === null) return;
        const files = Array.from(event.target.files);
        //if (onAddImage) onAddImage(files[0]);
        if (onAddImage) {
            convertFileToBase64(files[0], (str: any) => onAddImage(str))
            onAddImage(files[0]);
        }
        if (onAddMultiple) onAddMultiple(files);
        if (disableAutoPreview) return;
        if (!addMoreType && files[0] !== undefined) {
            reader.onload = () => {
                if (reader.result) {
                    setPreview(reader.result);
                }
            };

            reader.readAsDataURL(files[0]);
        }
    }

    function handleRemoveImage() {
        setPreview('');
        if (onRemoveImage) onRemoveImage();
    }

    useEffect(() => {
        if (bgImage !== undefined) {
            setPreview(bgImage);
        }
        if (!addMoreType && previewFile && previewFile !== '-1') {
            const reader = new FileReader();

            reader.onload = () => {
                setPreview(reader.result);
            };

            reader.readAsDataURL(previewFile);
        }
    }, [previewFile, bgImage]);

    return(
        <>
            {imgDefalt ?
                <img
                    className={`rounded-md bg-[#d4d4d4]  border border-4 border-border ${width ? width : 'w-[100px]'} ${height ? height : 'h-[100px]'} `}
                    src={preview ? preview : imgDefalt}
                />
                :

                <div className={`rounded-full  overflow-hidden relative ${width ? width : 'w-[100px]'} ${height ? height : 'h-[100px]'}`}>
                    <img
                        /*className={`${imgSquare ? 'rounded-md' : 'rounded-full'} ${homeIcon ? 'bg-[#d4d4d4]' : ''}  border border-4 border-border ${width ? width : 'w-[100px]'} ${height ? height : 'h-[100px]'} `}*/
                        className={`absolute  bottom-0 object-cover ${width ? width : 'w-[100px]'} ${height ? height : 'h-[100px]'} ${imgSquare ? 'rounded-md' : 'rounded-full'} ${homeIcon ? 'bg-[#d4d4d4]' : ''} border border-4 border-border`}
                        src={urlPrevew ? preview : (homeIcon ? noImageHome : noImage)}
                    />
                </div>
                /*className={`${imgSquare ? 'rounded-md' : 'rounded-full'} ${homeIcon ? 'bg-[#d4d4d4]' : ''}  border border-4 border-border ${width ? width : 'w-[100px]'} ${height ? height : 'h-[100px]'} `}*/
            }
            <div className={'gap-3 flex flex-col'}>
                <div className="">
                    <label
                        htmlFor={id ? id : "upload"}
                        className="flex flex-col items-center gap-2 cursor-pointer"
                    >
                    <ImageAdd color={'#E0A422'}/>
                    </label>
                    <input
                        multiple={true}
                        onChange={handleChange}
                        id={id ? id : "upload"}
                        type="file"
                        className="hidden"
                    />
                </div>
                {/*<button type={'button'}><ImageAdd color={'#E0A422'}/></button>*/}
                <button type={'button'} onClick={handleRemoveImage}>
                    <Delete color={'#E0A422'}/>
                </button>
            </div>
        </>
    )
}